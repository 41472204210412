import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Page, PageHeader, PageTitle, PageContent, StyledLink, PageFooter } from '../../../components/UI';
import { PaymentLogos } from '../../../components/payment';
import { EventHelper } from '../../../helpers';
import { DomainSetting } from '../../../settings/appSettings';
import { contactLabel } from '../../../settings/contactInfo';
import { DomainType } from '../../../store/enums';

export const ContactsPage = () => {
  const { t } = useTranslation();
  const { getContactsByDomain } = EventHelper;

  const contacts: contactsType = getContactsByDomain();
  const isEuDomain = location.host.includes(DomainSetting.Eu);
  const isDomainKz = EventHelper.getDomainType(window.location.host) === DomainType.Kz;

  const contactsTitle = isDomainKz ? 'Контакты' : t<string>('Contacts.Contacts');
  const emailTitle = isDomainKz ? 'Почта' : t<string>('Contacts.ContactEmail');
  const NameOfLegalEntityTitle = isDomainKz ? 'Юридическое лицо' : t<string>('Contacts.NameOfLegalEntity');
  const RegisteredAddressTitle = isDomainKz ? 'Юридический адрес:' : 'Registered address:';
  const RegistrationNumberTitle = isDomainKz ? 'Регистрационный номер' : t('Contacts.RegistrationNumber');
  const ScheduleTitle = isDomainKz ? 'Время работы' : t('Contacts.Schedule');

  return (
    <Page title={contactsTitle}>
      <Header>
        <PageTitle>{contactsTitle}</PageTitle>
      </Header>
      <PageContent>
        <Address>
          <ContactItem label={emailTitle} description={contacts.email} type="email" />
          <ContactItem label={NameOfLegalEntityTitle} description={contacts.nameOfTheLegalEntity} />
          <ContactItem label={RegisteredAddressTitle} description={contacts.registeredAddress} />
          <ContactItem label={RegistrationNumberTitle} description={contacts.registrationNumber} />
          {contacts.employerIdentificationNumber && (
            <ContactItem
              label={
                isEuDomain
                  ? contactLabel.vatNumber
                  : isDomainKz
                  ? 'Идентификационный номер работодателя'
                  : contactLabel.employerIdentificationNumber
              }
              description={contacts.employerIdentificationNumber}
            />
          )}
          <ContactItem label={ScheduleTitle} description={contacts.schedule} />
        </Address>
      </PageContent>
      <Footer>
        <PaymentLogos />
      </Footer>
    </Page>
  );
};

interface IContactItemProps {
  label?: string;
  description: string;
  type?: 'phone' | 'email';
  note?: string;
  renderNote?: () => JSX.Element;
}

type contactsType = {
  email: string;
  nameOfTheLegalEntity: string;
  registeredAddress: string;
  registrationNumber: string;
  employerIdentificationNumber: string;
  schedule: string;
};

const ContactItem: FC<IContactItemProps> = ({ type, note, renderNote, label, description }) => {
  let information;
  switch (type) {
    case 'phone':
      information = (
        <StyledLink primary href={`tel:${description}`} title={`${label}${description}`}>
          {description}
        </StyledLink>
      );
      break;
    case 'email':
      information = (
        <StyledLink primary href={`mailto:${description}`} title={`${label}${description}`}>
          {description}
        </StyledLink>
      );
      break;
    default:
      information = <span>{description}</span>;
      break;
  }
  return (
    <ContactItemContainer>
      {label && <Label>{label}</Label>}
      <P>{information}</P>
      {note && <span>{note}</span>}
      {!note && !!renderNote && renderNote()}
    </ContactItemContainer>
  );
};

const Header = styled(PageHeader)`
  min-height: unset;
  height: unset;
  text-align: left;
  padding: 20px 25px 0 25px;
  align-items: start;
`;

const Address = styled.address`
  margin: 0 0 20px 0;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
`;

const ContactItemContainer = styled.section`
  margin-bottom: 20px;
`;

const Label = styled.h3`
  font-size: 14px;
  margin: 0;
  color: ${(props) => props.theme.colors.textSecond};
  font-family: ${(props) => props.theme.fonts.lighterFont};
`;

const P = styled.p`
  margin: 10px 0;
`;

const Footer = styled(PageFooter as any)`
  padding: 0 25px 25px 25px;
`;

export default ContactsPage;
