import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  Page,
  PageContent,
  PageHeader,
  PageTitle,
  PaymentDocumentsLink,
  UserAgreementLink,
} from '../../../components/UI';
import { PaymentLogos } from '../../../components/payment';
import { EventHelper } from '../../../helpers';
import { DomainType } from '../../../store/enums';

export const PaymentsInfoPage = () => {
  const { t } = useTranslation();
  const { getDomainType } = EventHelper;
  const isDomainKz = getDomainType(window.location.host) === DomainType.Kz;

  const PaymentMethodsTitle = isDomainKz ? 'Методы оплаты' : t('PaymentsInfo.PaymentMethods');
  const TicketPaymentMethodTitle = isDomainKz ? 'Способы оплаты билета' : t('PaymentsInfo.TicketPaymentMethod');

  return (
    <Page title={PaymentMethodsTitle}>
      <Header>
        <PageTitle>{PaymentMethodsTitle}</PageTitle>
      </Header>
      <PageContent>
        <H2>{TicketPaymentMethodTitle}</H2>
        {isDomainKz && (
          <>
            <P>Вы можете оплатить нужный Вам билет с помощью банковской карты.</P>
            <P>
              После нажатия кнопки «Оплатить» вы будете перенаправлены на специальную защищенную страницу оплаты
              процессинговой системы. На странице оплаты будут указаны номер заказа и сумма платежа. Для оплаты вам
              необходимо ввести данные вашей карты и подтвердить платеж, нажав кнопку «Оплатить». Если ваша карта
              поддерживает технологию 3-D Secure, вам будет предложено пройти стандартную одноминутную процедуру
              проверки держателя карты на сайте вашего банка (банка, выпустившего вашу карту).
            </P>
            <P>
              После успешной оплаты вы автоматически получите уведомление по электронной почте. Настоятельно рекомендуем
              вам сохранить платежные данные, это ваше подтверждение оплаты и может быть полезно в случае возникновения
              спорной ситуации
            </P>
            <P>Мы принимаем платежи следующими кредитными картами: Visa, Visa Electron, MasterCard, Maestro</P>
          </>
        )}
        {!isDomainKz && (
          <>
            <P>{t('PaymentsInfo.AfterPressingButton')}</P>
            <P>{t('PaymentsInfo.RedirectToPaymentPage')}</P>
            <P>{t<string>('PaymentsInfo.ProofOfPayment')}</P>
            {/* <P> */}
            {/*  <PaymentDocumentsLink primary /> */}
            {/* </P> */}
            <P>{t('PaymentsInfo.AcceptCreditCards')}</P>
          </>
        )}
        <LogosWrapper>
          <PaymentLogos />
        </LogosWrapper>
        {isDomainKz && (
          <>
            <P>
              Безопасность вводимых данных обеспечивается использованием специальных протоколов SSL и гарантирующих
              безопасную передачу данных в сети
            </P>
            <P>
              После успешной оплаты, вы получите билеты по электронной почте, которую вы указали. Вам необходимо будет
              распечатать полученный билет со штрихкодом.
            </P>
            <p style={{ fontSize: '14px', margin: 0 }}>Условия возврата следующие:</p>
            <UserAgreementLink primary />
          </>
        )}
        {!isDomainKz && (
          <>
            <P>
              {t('PaymentsInfo.BankCardPayments')}. {t('PaymentsInfo.SystemPaymentPage')}{' '}
              {t('PaymentsInfo.SafetyRequirements')}
            </P>
            <P>{t<string>('PaymentsInfo.Refund')}</P>
            <P>
              {t('PaymentsInfo.ReturnConditions')} <UserAgreementLink primary />
            </P>
          </>
        )}
      </PageContent>
    </Page>
  );
};

const Header = styled(PageHeader as any)`
  min-height: unset;
  height: unset;
  text-align: left;
  padding: 20px 25px 0 25px;
  align-items: start;
`;

const H2 = styled.h2`
  font-size: 16px;
`;

const P: any = styled.p`
  margin: 0 0 20px 0;
  font-size: 14px;
`;

const LogosWrapper = styled.div`
  margin-bottom: 45px;
`;
