import React, { FC, memo, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useContractData } from '../../helpers/customHooks';
import { ContactInfo } from '../../settings/contactInfo';
import { PaymentSettings } from '../../settings/paymentSettings';
import { EventHelper } from '../../helpers';
import { DomainType } from '../../store/enums';

const isDomainRu = EventHelper.getDomainType(window.location.host) === DomainType.Ru;
const isDomainKz = EventHelper.getDomainType(window.location.host) === DomainType.Kz;

export const UserAgreementLink: FC<FileLinkPropsType> = memo(({ children, onClick }) => {
  const { t } = useTranslation();
  const { bonusProgramItems, publicContractItems } = useContractData();

  return isDomainKz ? (
    <Container>
      <StyledContractContainer>{children}</StyledContractContainer>
      <StyledContractContainer>
        <StyledContractHeader>Политика Конфиденциальности</StyledContractHeader>
        <StyledContractBody>
          <StyledContractLink
            href={`content/documents/Политика_конфиденциальности.docx`}
            target="_blank"
            onClick={onClick}
          >
            ru
          </StyledContractLink>
        </StyledContractBody>
      </StyledContractContainer>
      <StyledContractContainer>
        <StyledContractHeader>Публичная Оферта</StyledContractHeader>
        <StyledContractBody>
          <StyledContractLink href={`content/documents/Публичная_оферта.docx`} target="_blank" onClick={onClick}>
            ru
          </StyledContractLink>
        </StyledContractBody>
      </StyledContractContainer>
      <StyledContractContainer>
        <StyledContractHeader>Бонусная Программа</StyledContractHeader>
        <StyledContractBody>
          <StyledContractLink href={`content/documents/Бонусная_программа.docx`} target="_blank" onClick={onClick}>
            ru
          </StyledContractLink>
        </StyledContractBody>
      </StyledContractContainer>
    </Container>
  ) : (
    <Container>
      <StyledContractContainer>{children}</StyledContractContainer>
      <StyledContractContainer>
        <StyledContractHeader>Privacy policy</StyledContractHeader>
        <StyledContractBody>
          <StyledContractLink href={ContactInfo.PrivacyPolicyFileUrl} target="_blank" onClick={onClick}>
            eng
          </StyledContractLink>
        </StyledContractBody>
      </StyledContractContainer>
      <StyledContractContainer>
        <StyledContractHeader>Concert Rules</StyledContractHeader>
        <StyledContractBody>
          <StyledContractLink
            href="https://www.livenation.pl/regulaminkoncertumaxkorzh"
            target="_blank"
            onClick={onClick}
          >
            eng
          </StyledContractLink>
        </StyledContractBody>
      </StyledContractContainer>
      <StyledContractContainer>
        <StyledContractHeader>{t<string>('Menu.PublicAgreement')}</StyledContractHeader>
        {publicContractItems?.map((item) => (
          <StyledContractBody key={item.title}>
            <StyledContractLink href={item.path} target="_blank" onClick={onClick}>
              {item.title}
            </StyledContractLink>
          </StyledContractBody>
        ))}
      </StyledContractContainer>
      <StyledContractContainer>
        <StyledContractHeader>{t<string>('Menu.BonusProgram')}</StyledContractHeader>
        {bonusProgramItems?.map((item) => (
          <StyledContractBody key={item.title}>
            <StyledContractLink href={item.path} target="_blank">
              {item.title}
            </StyledContractLink>
          </StyledContractBody>
        ))}
      </StyledContractContainer>
    </Container>
  );
});

export const OnlineCardPaymentLink: FC<FileLinkPropsType> = memo(({ title, onClick, primary }) => {
  const { t } = useTranslation();
  const path = isDomainRu ? ContactInfo.OnlineCardPaymentRuFileUrl : ContactInfo.OnlineCardPaymentFileUrl;

  return (
    <StyledLink
      primary={primary}
      href={path}
      title={t('Menu.CardPayment')}
      target="_blank"
      onClick={(e) => onClick && onClick(e)}
    >
      {title || t<string>('Menu.CardPayment')}
    </StyledLink>
  );
});

export const PaymentDocumentsLink: FC<FileLinkPropsType> = memo(({ onClick, title, primary }) => {
  const { t } = useTranslation();
  const path = isDomainRu ? ContactInfo.PaymentDocumentsRuFileUrl : ContactInfo.PaymentDocumentsFileUrl;

  return (
    <StyledLink
      primary={primary}
      href={path}
      title={t('Menu.ConfirmDocument')}
      target="_blank"
      onClick={(e) => onClick && onClick(e)}
    >
      {title || t<string>('Menu.ConfirmDocument')}
    </StyledLink>
  );
});

export const BePaidLink = () => (
  <StyledLink primary href={PaymentSettings.BEPAID_URL} title={i18next.t('PaymentsInfo.BePaidLink')} target="_blank">
    bePaid
  </StyledLink>
);

export const RLink = styled(Link as any)<IStyledLinkProps>`
  text-decoration: none;
  color: ${(props) => (props.primary ? props.theme.colors.accent : props.theme.colors.text)};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.textSecond};
  }
`;

export const StyledLink = styled.a<IStyledLinkProps>`
  text-decoration: none;
  color: ${(props) => (props.primary ? props.theme.colors.accent : props.theme.colors.text)};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  display: ${(props) => (props.display ? 'block' : 'inline-block')};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.textSecond};
  }
`;

export const StyledContractLink = styled.a<IStyledLinkProps>`
  text-decoration: underline;
  color: #ffbf00;
`;

export const StyledContractBody = styled.div<IStyledLinkProps>`
  margin-left: 10px;
`;

export const StyledContractHeader = styled.div<IStyledLinkProps>`
  color: #ffbf00;
`;

export const StyledContractContainer = styled.div<IStyledLinkProps>`
  display: flex;
  justify-content: flex-start;
  cursor: default;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

type FileLinkPropsType = {
  title?: string;
  onClick?: (e: SyntheticEvent) => void;
  children?: React.ReactNode;
  primary?: boolean;
};

interface IStyledLinkProps {
  disabled?: boolean;
  hover?: boolean;
  active?: boolean;
  primary?: boolean;
  logged?: boolean;
  hostRef?: any;
  display?: string;
}
