import { AppSettings } from './appSettings';

export class ContactInfo {
  public static OnlineCardPaymentFileUrl = `/content/documents/${AppSettings.APP_NAME}_Paid.pdf`;
  public static OnlineCardPaymentRuFileUrl = `/content/documents/${AppSettings.APP_NAME}_Paid_Ru.pdf`;
  public static PaymentDocumentsFileUrl = `/content/documents/${AppSettings.APP_NAME}_Documents.pdf`;
  public static PaymentDocumentsRuFileUrl = `/content/documents/${AppSettings.APP_NAME}_Documents_Ru.pdf`;
  public static PrivacyPolicyFileUrl = `content/documents/Fanaticka_Privacy_Policy.docx`;
  public static WebsiteUrl = 'https://fanaticka.live';
}

export const contactLabel = {
  vatNumber: 'VАТ number:',
  employerIdentificationNumber: 'Employer Identification Number:',
};

export const contacts = {
  live: {
    email: 'concerts@fanaticka.live',
    nameOfTheLegalEntity: 'FANATICKA, INC.',
    registeredAddress: '651 N Broad St, STE 206, Middletown, 19709, State of Delaware, USA.',
    registrationNumber: '7954586',
    employerIdentificationNumber: '85-2103078',
    schedule: '24/7',
  },
  kz: {
    email: 'support@fanaticka.live',
    nameOfTheLegalEntity: 'ТОО "TICKETBAR(ТИКЕТБАР)"',
    registeredAddress:
      'Юридический Адрес: Казахстан, Алматы, улица Нусупбекова, дом 10\n' +
      '\n' +
      'Фактический Адрес: Казахстан, город Алматы, улица Достык, дом 128, н.п. 180.\n' +
      '\n' +
      'БИН (ИИН): 240340024719\n' +
      '\n' +
      'Банк: АО "Kaspi Bank"\n' +
      '\n' +
      'КБе: 17\n' +
      '\n' +
      'БИК: CASPKZKA\n' +
      '\n' +
      'ИИК: KZ20722S000035660659',
    registrationNumber: '240340024719',
    employerIdentificationNumber: 'CASPKZKA',
    schedule: '24/7',
  },
  eu: {
    email: 'concerts@fanaticka.live',
    nameOfTheLegalEntity: 'Fanaticka OÜ',
    registeredAddress: 'Ruunaoja tn 3--81, Tallinn, 11415',
    registrationNumber: '16344598',
    employerIdentificationNumber: 'EE102444470',
    schedule: '24/7',
  },
  by: {
    email: 'concerts@fanaticka.live',
    nameOfTheLegalEntity: 'ООО "ФАНАТИКАКОМ"',
    registeredAddress: 'Республика Беларусь, 220036, г. Минск, улица Карла Либкнехта, 66, помещение 21Б (офис 3)',
    registrationNumber: '193110762, 24.07.2018, Главное управление юстиции Мингорисполкома',
    schedule: 'Круглосуточно',
  },
  ru: {
    email: 'concerts@fanaticka.live',
    nameOfTheLegalEntity: 'ООО "ФАНАТИКАРУ"',
    registeredAddress: 'РФ, г. Москва УЛИЦА БИРЮЛЁВСКАЯ, ДОМ 24 КОРПУС 1 ПОМ 3 КОМ 2 ОФ 77',
    registrationNumber: '19.02.2020, Инспекция Федеральной налоговой службы №24 по г. Москве',
    schedule: 'Круглосуточно',
  },
};
