import dayjs from 'dayjs';
import { IEventStoreState } from '../store/event/types';
import { IBookTicketQuotaStoreState, IVenueSectorStoreState } from '../store/ticket/types';
import Constants from '../store/constants';
import { IEventDetailsStoreState } from '../store/event/eventDetails/EventDetailsStoreState';
import { DomainType } from '../store/enums';
import { DomainSetting } from '../settings/appSettings';
import { contacts } from '../settings/contactInfo';

export class EventHelper {
  public static getHasTickets(event: IEventStoreState): boolean {
    return (
      this.getHasQuotas(event) &&
      event.sectors.some((s) => s.ticketQuotas.reduce((sum, current) => (sum += current.left), 0) > 0)
    );
  }

  public static getHasSingleQuota(event: IEventStoreState): boolean {
    return this.getHasQuotas(event) && event.sectors.length === 1 && event.sectors[0].ticketQuotas.length === 1;
  }

  public static hasSectorTickets(sector: IVenueSectorStoreState): boolean {
    return sector && sector.ticketQuotas && sector.ticketQuotas.some((tq) => tq.left > 0);
  }

  public static getQuotaHasTickets(quota: IBookTicketQuotaStoreState): boolean {
    return !!quota && quota.left > 0;
  }

  public static isEventDistributed(event: IEventStoreState, userEvents?: Array<IEventStoreState>): boolean {
    return !!event && userEvents.some((e) => e.slug === event.slug);
  }

  public static isEventDetailsDistributed(
    event: IEventDetailsStoreState,
    userEvents?: Array<IEventStoreState>
  ): boolean {
    return !!event && userEvents.some((e) => e.slug === event.slug);
  }

  public static getEventDetailsMetaDescription(event: IEventDetailsStoreState): string {
    if (!event) {
      return null;
    }
    const title = `${event.title} - ${event.subtitle}`;
    const date = `${dayjs(event.date).format(Constants.DateFormat)}`;
    const place = event.venue ? `${event.venue.title}, ${event.venue.city}` : '';
    return `${title} ${Constants.BulletDelimiter} ${date} ${place && `${Constants.BulletDelimiter} ${place}`}`;
  }

  public static getMetaDescription(event: IEventStoreState): string {
    if (!event) {
      return null;
    }
    const title = `${event.title} - ${event.subtitle}`;
    const date = `${dayjs(event.date).format(Constants.DateFormat)}`;
    const place = event.venue ? `${event.venue.title}, ${event.venue.city}` : '';
    return `${title} ${Constants.BulletDelimiter} ${date} ${place && `${Constants.BulletDelimiter} ${place}`}`;
  }

  public static getHasQuotas(event: IEventStoreState): boolean {
    return !!event && !!event.sectors && !!event.sectors.some((s) => s.ticketQuotas.length);
  }

  public static getDomainType(domainName: string): number {
    const parseDomain = domainName.split('.');
    const domain = parseDomain[parseDomain.length - 1];

    switch (domain) {
      case DomainSetting.By:
        return DomainType.By;
      case DomainSetting.Kz:
        return DomainType.Kz;
      case DomainSetting.Ru:
        return DomainType.Ru;
      case DomainSetting.Pl:
        return DomainType.Pl;
      case DomainSetting.Co:
        return DomainType.Co;
      case DomainSetting.Info:
        return DomainType.Info;
      case DomainSetting.Live:
        return DomainType.Live;
      case DomainSetting.Me:
        return DomainType.Me;
      case DomainSetting.Eu:
        return DomainType.Eu;
      case DomainSetting.Com:
        return DomainType.Com;
      default:
        return DomainType.None;
    }
  }

  public static getDomainTopLevel = (domainType) => {
    switch (domainType) {
      case DomainType.By:
        return DomainSetting.By;
      case DomainType.Ru:
        return DomainSetting.Ru;
      case DomainType.Pl:
        return DomainSetting.Pl;
      case DomainType.Co:
        return DomainSetting.Co;
      case DomainType.Info:
        return DomainSetting.Info;
      case DomainType.Live:
        return DomainSetting.Live;
      case DomainType.Me:
        return DomainSetting.Me;
      case DomainType.Eu:
        return DomainSetting.Eu;
      case DomainType.Com:
        return DomainSetting.Com;
      case DomainType.Kz:
        return DomainSetting.Kz;
      default:
        return DomainSetting.By;
    }
  };
  public static getContactsByDomain = () => {
    const domainName = location.host;
    const parseDomain = domainName.split('.');
    const domain = parseDomain[parseDomain.length - 1];

    return contacts[domain] ?? contacts.live;
  };
}

export default EventHelper;
