import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '../UI';
import { useScript } from '../../helpers/customHooks';
import { IGlobalStoreState } from '../../store';
import RouteService from '../../services/routeService';
import { Pages } from '../../store/enums';
import { Routes } from '../../store/routes';

declare const PayBox: any;

export const FreedomPayForm: FC = () => {
  const { t } = useTranslation();
  const { pricePayByCard, currency } = useSelector((state: IGlobalStoreState) => state.checkout.init);
  const { paymentTimeoutAt, referenceNumber } = useSelector((state: IGlobalStoreState) => state.checkout.process);
  const { email, process } = useSelector((state: IGlobalStoreState) => state.checkout);
  const { isLoading } = useSelector((state: IGlobalStoreState) => state.app);

  useScript(`https://cdn.freedompay.kz/widget/pbwidget.js?${new Date().getTime()}`);
  const routeSrv = new RouteService();
  const navigate = useHistory();

  const handlePayment = async () => {
    // @ts-ignore
    const isProd = window.ENVIRONMENT === 'production';
    const bookResultLinkTemplate = `${routeSrv.getBaseUrl()}/${Pages.Book}/result`;
    const data = {
      // @ts-ignore
      token: window.REACT_APP_FREEDOM_PAY_API_PUBLISHABLE_TOKEN,
      payment: {
        order: referenceNumber,
        amount: pricePayByCard.toString(),
        currency: 'KZT',
        description: 'Tickets',
        expires_at: paymentTimeoutAt.toISOString(),
        test: isProd ? 0 : 1, // 0 is prod mode, 1 is testing mode
        options: {
          callbacks: {
            result_url: bookResultLinkTemplate,
            success_url: bookResultLinkTemplate,
          },
        },
        user: {
          email,
          phone: '77777777777',
        },
      },
      successCallback() {
        navigate.replace(`${Routes.BookResult}?payment_intent=${process.referenceNumber}`);
      },
      errorCallback() {
        navigate.replace(`${Routes.BookResult}?payment_intent=0`);
      },
    };

    const widget = new PayBox(data);
    widget.create();
  };

  return (
    <div style={{ margin: 'auto', width: '100%' }}>
      <Button disabled={isLoading || !referenceNumber || !currency} onClick={handlePayment}>
        {t('Checkout.BuyTicket')}
      </Button>
    </div>
  );
};
